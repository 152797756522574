import React, {useState} from 'react'
import './aboutteam.css'
import { Container, Row } from 'react-bootstrap'
// import img1 from '../images/anandimg.webp'
// import img2 from '../images/goelimg.webp'


import img1 from '../images/Vibhor.webp'
import img2 from '../images/Pulkit.webp'

import hoverimg1 from '../images/team-hover1.webp'
import hoverimg2 from '../images/team-hover2.webp'

// import simg1 from '../images/Senior-member1.webp'
// import simg2 from '../images/Senior-member2.webp'
// import simg3 from '../images/Senior-member3.webp'

import simg1 from '../images/Vineet.webp'
import simg2 from '../images/Aditya Bhuttani.webp'
import simg3 from '../images/Sagar-Suri.webp'
import simg4 from '../images/Aditya-Agarwal.webp'
import simg5 from '../images/Pushpak-Jindal.webp'


const seniors = [
    { id: 1, 
      img: simg1, 
      name: 'Vineet Sharma', 
      position: "Associate Director",
      text:"10+ years of experience in business valuation and consulting with a sectoral expertize in real estate, financial services, technology and retail.", 
      linkedin:'https://www.linkedin.com/in/cavineetsharma/',  
    },
    { id: 2, 
        img: simg2, 
        name: 'Aditya Bhuttani', 
        position: "Principal Consultant",
        text:"9+ years of experience in investment banking and corporate strategy with a sectoral expertize in real estate, financial services and hospitality.", 
        linkedin:'https://www.linkedin.com/in/adityabhuttani/',  
    },
    { id: 3, 
        img: simg3, 
        name: 'Sagar Suri', 
        position: "Principal Consultant",
        text:"8+ years of experience with expertise in corporate strategy, management consulting, M&A advisory, and financial advisory.", 
        linkedin:'https://www.linkedin.com/in/sagarsuridce/',  
    },
    { id: 4, 
        img: simg4, 
        name: 'Aditya Agarwal', 
        position: "Principal Consultant",
        text:"10+ years of experience and a demonstrated history of working in Real Estate, Hospitality, Healthcare, and BFSI sectors.", 
        linkedin:'https://www.linkedin.com/in/aditya-agarwal94/details/experience/',  
    },
    { id: 5, 
        img: simg5, 
        name: 'Pushpak Jindal', 
        position: "Principal Consultant (Data Analytics)",
        text:"Seasoned data analytics & data engineering leader with 11+ years of experience with a demonstrated history of working in the e-learning, e-commerce, and BFSI industry.", 
        linkedin:'https://www.linkedin.com/in/pushpak-jindal-bb577328/',  
    },
    // { id: 2, 
    //     img: simg2, 
    //     name: 'Sagar Suri', 
    //     position: "Principal Consultant",
    //     text:" 8+ years of expertise in strategy and financial advisory with a sectoral expertise in real estate, financial services and M&A advisory.", 
    //     linkedin:'https://www.linkedin.com/in/sagar-suri-939a5958?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',  
    //   },
    // { id: 2, 
    //     img: simg2, 
    //     name: 'Gautam Chhabra', 
    //     position: "Principal Consultant",
    //     text:"7+ years of experience in strategy and financial consulting with a sectoral expertize in real estate, technology, oil and gas and retail.", 
    //     linkedin:'https://www.linkedin.com/in/gautamchhabra/',  
    //   },

  ];


const Abouttteam = () => {
    
    const [isShown1, setIsShown1] = useState(false);
    const [isShown2, setIsShown2] = useState(false);

    
    return (
        <>
            <section className='aboutteam-section py-5'>
                <Container>
                    <Row className='mobile'>
                        <div>
                            <div className='content-box '>
                                <h2>Meet</h2>
                                <div className="strok">Team</div>
                                <div className="h1 anima"><span className='anima-underline'>The </span> Team</div>
                            </div> 
                        </div>
                    </Row>

                    <Row className='g-5'>
                        <div className='col-md-6 col-sm-12 ordersm-1'>
                            <div className='content-box desktop'>
                                    <h2>Meet</h2>
                                    <div className="strok">Team</div>
                                    <div className="h1 anima"><span className='anima-underline'>The </span> Team</div>
                               </div> 
                            <div>
                                <div className='imgdiv1 team-hover-box'>
                                    <div className='team-image-box'>
                                        <img className='imgdef' style={(isShown1) ? {display:"none"} : {display:"block"}} src={img1} alt='' />
                                        <img 
                                         onMouseEnter={() => setIsShown1(true)}
                                         onMouseLeave={() => setIsShown1(false)}
                                        className='hoverimg' style={(isShown1) ? {display:"block"} : {display:"none"}} src={hoverimg2} alt='' />
                                    </div>    
                                    <div className='bgblue'>
                                        <div>
                                            <h4>Vibhor Anand</h4>
                                            <h5>Managing Director</h5>
                                        </div>
                                        <div>
                                            <div className='mt-3 text-center'><a href="https://www.linkedin.com/in/vibhoranand86/" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                                <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                            </svg></a></div>
                                        </div>
                                    </div>
                                    <div className='hover-text-box'
                                    onMouseEnter={() => setIsShown1(true)}
                                    onMouseLeave={() => setIsShown1(false)} >
                                        <div className='hover-text'>
                                            <p>A pen nerd - Piston pump are Fountain pens’ soul, cartridges are for the weak hearted</p>
                                            {/* <h4>400</h4> */}
                                        </div>
                                        <div className='hover-text'>
                                            <p>Secretly believes in reverse aging (Okinawans rock!)</p>
                                            {/* <h4>5</h4> */}
                                        </div>
                                        <div className='hover-text'>
                                            <p>A firm believer of the Pareto Principle. You can't beat the odds!</p>
                                            {/* <h4>1</h4> */}
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    <p className='para1'>
                                        
                                    Vibhor is the Managing Director at Preferred Square. During the course of his 15+ years of experience, he has worked with over a hundred asset managers in the LP GP ecosystem with a total AUM of more than USD 3 Tn. From setting up new departments, streamlining processes and refining the GTM strategy to managing large scale delivery teams, he specializes in operationalizing offshore consulting.
                                    He loves to read on finance, fitness and philosophy. An active badminton and squash player, he now has his eyes set on learning how to play golf. 
                                        {/* Vibhor is the Managing Director at Preferred Square. During the course of his 15+ years of experience, he has worked with over a hundred asset managers in the LP GP ecosystem with a total AUM of more than USD 3 Tn. From setting up delivery teams, ground up, to managing and leading large-scale delivery teams, he specializes in operationalizing offshore consulting.
                                        His sectoral expertise lies in [list 3-5 sectors].
                                        He loves to read on philosophy, economics and fitness. An active badminton, squash and basketball player, he now has his eyes set on learning how to play golf.  */}
                                        
                                        {/* <div className='mt-3 text-center'><a href="https://www.linkedin.com/in/vibhoranand86/" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                            <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                        </svg></a></div> */}

                                        </p>

                                </div>                        
                            </div>
                       
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <div className='mt-5'>
                                <div className='imgdiv team-hover-box'>
                                    <div className='team-image-box d-flex justify-content-end'>
                                        <img className='imgdef' style={(isShown2) ? {display:"none"} : {display:"block"}} src={img2} alt='' />
                                        <img 
                                        onMouseEnter={() => setIsShown2(true)}
                                        onMouseLeave={() => setIsShown2(false)}
                                        className='hoverimg' style={(isShown2) ? {display:"block"} : {display:"none"}} src={hoverimg1} alt='' />
                                    </div>
                                    <div className='bgpink' >
                                        <div>
                                            <h4>Pulkit Goel</h4>
                                            <h5>Managing Director</h5>  
                                        </div>
                                        <div>
                                            <div className='mt-3 text-center'><a href="https://www.linkedin.com/in/pulkit-goel-cfa/" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                            <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                            </svg></a></div>
                                        </div>
                                    </div>
                                    <div className='hover-text-box'
                                     onMouseEnter={() => setIsShown2(true)}
                                     onMouseLeave={() => setIsShown2(false)}
                                         >
                                        <div className='hover-text'>
                                            <p>Fun fact challenge with his daughter is a morning ritual. </p>
                                            {/* <h4>400</h4> */}
                                        </div>
                                        <div className='hover-text'>
                                            <p>A running enthusiast. Has run over 1500 Km</p>
                                            {/* <h4>5</h4> */}
                                        </div>
                                        <div className='hover-text'>
                                            <p> Amateur sportsman. If there is a game on, he will be on the court</p>
                                            {/* <h4>1</h4> */}
                                        </div>
                                    </div>                                   
                                </div>
                                <div className='seconddiv'>

                                    {/* <p className='para2'>With over 13 years of experience in Management Consulting, Investment Banking, and Corporate Finance, Pulkit has led valuation advisory to large family holdings and RE giants globally. During his EY stint, Pulkit spearheaded the execution of the Microsoft-LinkedIn acquisition deal exceeding $30 Bn</p> */}
                                    <p className='para2'>
                                    Pulkit is the Managing Director and Founder of Preferred Square. He started his career in investment banking and gained exposure across sell side and buy side mandates while participating in deals worth more than USD 300 Bn. After having worked in corporate finance and valuation advisory for over 10 years, he pivoted to establish Preferred Square.

                                        His sectoral expertise lies in Real Estate, Financial Services, Energy, Technology, Retail & Manufacturing.

                                        A runner and an active badminton and squash player, he loves to travel and hike off-beat paths.
                                        {/* Pulkit is the managing director and founder of Preferred Square. He started his career in investment banking and gained exposure across sell side and buy side while participating in deals worth more than USD 30 Bn. After having worked in corporate finance and valuation advisory for over 10 years, he pivoted to establish Preferred Square.His sectoral expertize lies in Real Estate, Financial Services, Energy, Technology, Retail & Manufacturing.A runner and an active badminton and squash player, he loves to travel and hike off-beat paths. */}
                                    
                                    {/* <div className='mt-3 text-center'><a href="https://www.linkedin.com/in/pulkit-goel-cfa/" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                        <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                    </svg></a></div> */}

                                    </p>
                                </div>
                          </div>  
                          
                        </div>
                    </Row>

                    {/* desktop senior team */}
                    <Row className='mt-5'>
                        <div className='col-md-12'>
                            <div className='content-box senior-team text-center'>
                                <div className='relative'>
                                    <h2>Senior</h2>
                                    <div className="strok">Team</div>
                                    <div className="h1 anima"><span className='anima-underline'>Team</span> </div>
                                </div>
                            </div> 

                        </div>
                    </Row>

                    <Row className='senior-teams'>

                    {seniors.map((d, i) => (
                    <div key={d.id} className={(d.id === 1 || d.id === 3 || d.id === 5) ? 'col-md-4 offset-2 mb-5' : 'col-md-4 mb-5' }>
                        <div className='s-team'>
                            <div className='relative img-box'>
                                <img src={d.img} alt='' />
                                <div className='linkedin-icon'><a href={d.linkedin}><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                    <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                </svg></a></div>
                            </div>

                            <div className='details'>
                                    <div>
                                        <h4>{d.name}</h4>
                                        <h5>{d.position}</h5>   
                                    </div>
                                <p>{d.text}</p>
                                
                                {/* <div><a href={d.linkedin}><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                    <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                </svg></a></div> */}
                            </div>
                        </div>
                    </div>


                    ))}

                        {/* <Col className='col-md-4'>
                            <div className='s-team'>
                                <img src={simg1} alt='' />
                                <div className='details'>
                                    <h4>Vineet Sharma</h4>
                                    <h5>Principal Consultant</h5>
                                    <p>10+ years of experience in business valuation and consulting with a sectoral expertize in real estate, financial services, technology and retail</p>
                                    
                                    <div><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                        <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                                    </svg></div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>

            </section>

            {/* <section className='aboutteam-section mobile  mt-5 mb-4'>
                <Container>
                    <Row>
                        <div className='content-box'>
                            <h2>Meet</h2>
                            <div className="strok">The Team</div>
                            <div className="h1 anima"><span className='anima-underline'>The </span> Team</div>
                        </div>
                    </Row>
                </Container>
                <Container className='team-container'>
                    <Row>
                        <div className=''>
                            <img src={img2} alt='' className='img1' />
                            <div className='bgpink-mobile'>
                                <h4>Pulkit Goel</h4>
                                <h5>Managing Director</h5>
                            </div>
                        </div>
                        <p className='teampara1'>With over 13 years of experience in Management Consulting, Investment Banking, and Corporate Finance, Pulkit has led valuation advisory to large family holdings and RE giants globally. During his EY stint, Pulkit spearheaded the execution of the Microsoft-LinkedIn acquisition deal exceeding $30 Bn
                            <div className='mt-3 text-center'><a href="#" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                             </svg></a></div>

                        </p>
                    </Row>
                    <Row className='mt-5 pt-4'>
                        <div>
                            <div className=''>
                                <img src={img1} alt='' className='img2' />
                                <div className='bgblue-mobile'>
                                    <h4>Vibhor Anand</h4>
                                    <h5>Managing Director</h5>
                                </div>
                            </div>
                        </div>
                        <p className='teampara2'>
                            Vibhor is the Managing Director at Preferred Square. During the course of his 15+ years of experience, he has worked with over a hundred asset managers in the LP GP ecosystem with a total AUM of more than USD 3 Tn. From setting up delivery teams, ground up, to managing and leading large-scale delivery teams, he specializes in operationalizing offshore consulting.
                            His sectoral expertise lies in [list 3-5 sectors]
                            He loves to read on philosophy, economics and fitness. An active badminton, squash and basketball player, he now has his eyes set on learning how to play golf.

                            <div className='mt-3 text-center'><a href="#" target='blank'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
                                <path d="M19.4999 3.24219C10.8316 3.24219 3.80469 10.2691 3.80469 18.9374C3.80469 27.6058 10.8316 34.6327 19.4999 34.6327C28.1683 34.6327 35.1952 27.6058 35.1952 18.9374C35.1952 10.2691 28.1683 3.24219 19.4999 3.24219ZM15.6579 25.4428H12.4796V15.2147H15.6579V25.4428ZM14.0491 13.9591C13.0453 13.9591 12.3962 13.2479 12.3962 12.3683C12.3962 11.4707 13.0649 10.7808 14.09 10.7808C15.1151 10.7808 15.7429 11.4707 15.7625 12.3683C15.7625 13.2479 15.1151 13.9591 14.0491 13.9591ZM27.2658 25.4428H24.0875V19.7745C24.0875 18.4551 23.6265 17.5592 22.4771 17.5592C21.5992 17.5592 21.0776 18.1658 20.8471 18.7494C20.7621 18.9571 20.7408 19.2513 20.7408 19.544V25.4412H17.5609V18.4764C17.5609 17.1995 17.52 16.1319 17.4775 15.2131H20.2389L20.3844 16.6338H20.4482C20.8667 15.9668 21.8918 14.9826 23.6069 14.9826C25.6979 14.9826 27.2658 16.3837 27.2658 19.3952V25.4428Z" fill="white"/>
                             </svg></a></div>

                        </p>
                    </Row>
                    </Container>

            </section> */}
        </>
    )
}

export default Abouttteam