import React from "react";
// import { Col, Container, Row } from "react-bootstrap";
import "./WeserveCareer.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { NavLink } from "react-router-dom";
//import { ScrollToPlugin } from "gsap/ScrollToPlugin"


import rightImage1 from '../images/right-images-1.webp'
import rightImage2 from '../images/right-images-2.webp'
import rightImage3 from '../images/right-images-3.webp'
import rightImage4 from '../images/right-images-4.webp'


gsap.registerPlugin(ScrollTrigger);

const data = [
  { id: 1, img: rightImage1, title: "Mind-stimulating problem solving experience" },
  { id: 2, img: rightImage2, title: "Nimble & resilient team" },
  { id: 3, img: rightImage3, title: "Unwavering focus on creating impact" },
  { id: 4, img: rightImage4, title: "Mentorship driven, growth-led culture" },
];


 const WeServeCareer = () => {

  // const scrollToTop = () => {
  //   window.scrollTo(0, 0);
  // };


  return (
    <>
      <section id="lifeps" className="lifeat-ps-section-main weserve-section-main-top">
        <div className="container">
          <div className="row weserve-botrows-section">
            <div className="col-md-6">
              <div className="we-left-side-openings-header">
                  <div className="we-left-job-open-column-inner position-relative">
                        <div className="left-added-text-section">
                          <div className="we-job-absolute-text">
                              <p className="d-flex gap-4"><span>Join</span> Us</p>
                          </div>
                          <div className="we-latest-p-section">
                              <p className='text-white mb-0'>Join Us if you're</p>
                          </div>
                          <div className="we-bottom-jobber-section">
                              <h2 className='text-white mb-0'><span>Looking </span>for</h2>
                          </div>
                          <div className="we-bottom-jobber-section-mobile-viewer">
                              <h2 className='text-white  mb-0'>Looking for</h2>
                              <div className="animator-we-bottom-767-after">

                              </div>
                          </div>
                        </div>
                      <div className="after-767-para-content">
                        <p className="text-white mb-0">Enabling and empowering organizations
                            to unlock value with every decision they make
                        </p>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-md-6 right-scroll right-scroll-weserve-section">
              <div className="scroll-section career-page-scroll-sectioner">
                <div className="scroll-page row px-4">
                  {data.map((d, i) => (
                    // <Col className= {`col-md-6 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`}>
                    //     <div className='gredientbox'>
                    //         <div className='gredient-border'>
                    //             <img src={d.img} className="card-img" alt="..." />
                    //             <div className='overlay'></div>
                    //             <div className='overlay-text'>{d.title}</div>
                    //         </div>
                    //     </div>
                    // </Col>

                    //  <Col className={`col-md-6 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                    <div
                      className={`col-md-6 ${
                        d.id % 2 === 0
                          ? "second-classer-top"
                          : "remaining-class"
                      }`}
                      key={i}
                    >
                      <div className="gredient-border">
                        <div className="gredientbox">
                          {/* <NavLink to="/solution" onClick={scrollToTop}> */}
                            <img src={d.img} className="card-img" alt="..." />
                            <div className="overlay"></div>
                            <div className="overlay-text">{d.title}</div>
                          {/* </NavLink> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeServeCareer
