import React from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import './LifeAtPs.css';
import Accordion from 'react-bootstrap/Accordion';


                                                    
import parayas1 from '../images/ps-parayas01.webp';
import parayas2 from '../images/ps-parayas02.webp';
import parayas3 from '../images/ps-parayas03.webp';
import parayas4 from '../images/ps-parayas04.webp';
import parayas5 from '../images/ps-parayas05.webp';
import parayas6 from '../images/ps-parayas06.webp';
import parayas7 from '../images/ps-parayas07.webp';
import parayas8 from '../images/ps-parayas08.webp';
import parayas9 from '../images/ps-parayas09.webp';
import parayas10 from '../images/ps-parayas10.webp';
import parayas11 from '../images/ps-parayas11.webp';
import parayas12 from '../images/ps-parayas12.webp';
import parayas13 from '../images/ps-parayas13.webp';

export const LifeAtPs = () => {

    // const receivedData = props?.location?.state?.data;

    // console.log(receivedData , 'kdfhskdjfgsjkdf')

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // const [accordTab , setAccordTab] = useState('');
    // const [one , setOne] = useState(false);
    // const [two , setTwo] = useState(false);
    // const [three , setThree] = useState(false);
    // const [four , setFour] = useState(false);
    // const [five , setFive] = useState(false);
    // const [six , setSix] = useState(false);

    // const handleAccord1 = () => {
    //     setAccordTab('tab1');
    //     setOne(!one)
    // }
    // const handleAccord2 = () => {
    //     setAccordTab('tab2');
    //     setTwo(!two)
    // }
    // const handleAccord3 = () => {
    //     setAccordTab('tab3');
    //     setThree(!three)
    // }
    // const handleAccord4 = () => {
    //     setAccordTab('tab4');
    //     setFour(!four)
    // }
    // const handleAccord5 = () => {
    //     setAccordTab('tab5');
    //     setFive(!five)
    // }
    // const handleAccord6 = () => {
    //     setAccordTab('tab6');
    //     setSix(!six)
    // }

    // console.log(accordTab , "taaber")


//   useEffect(() => {

//   }, [])

  return (
    <>
      <section className='lifeat-ps-section-main life-preffered-square-section '  id='careers'>
          <div className="container before-992-life-at-ps-container">
            <div className='row life-at-preffer-row-section'>
                <div className="col-md-5 px-0">
                   <div className="after-992-ethos-header-content">
                    <div className="life-left-side-openings-header">
                        <div className="life-left-job-open-column-inner position-relative">
                            <div className="life-job-absolute-text">
                                <p>Ethos</p>
                            </div>
                            <div className="life-latest-p-section">
                                <p className='text-white mb-0'>Ethos of</p>
                            </div>
                            <div className="life-bottom-jobber-section">
                                <h2 className='text-white mb-0'><span>Life </span>@PS</h2>
                            </div>
                            <div className="life-bottom-jobber-section-mobile-viewer">
                                <h2 className='text-white  mb-0'>Life @PS</h2>
                                <div className="life-animator-we-bottom-767-after">

                                </div>
                            </div>
                        </div>
                    </div>
                   </div>                   
                </div>
                <div className="col-md-7">
                    <div className="right-side-accordion-content before-992-content-accordion-main">
                        <div className="right-side-accordion-inner">
                            <Accordion >  
                                <Accordion.Item eventKey="0" className='bg-properties-class-user accord-itemer-one'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        
                                        <div className="accord-head-section">
                                            <h2>#About Us</h2>
                                            <p>Learn about principles guiding Preferred Square</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="about-us-accord-body">
                                            <div className="about-us-accord-button text-center">
                                                <NavLink to="/about" onClick={scrollToTop} ><button className="text-center">Learn more about us</button></NavLink>
                                            </div>
                                        </div>
                                        <div className="bottom-pink-border-div">

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='bg-properties-class-user accord-itemer-two'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        <div className="accord-head-section">
                                            <h2>#Preferred4Life</h2>
                                            <p>Life at Preferred Square is more than just work</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="accordion-inner-body-section">
                                            <div className="accordion-preffered-list">
                                                {/* temp commnet */}
                                                {/* <div className="preffered-list-header">
                                                    <h2 className='mb-0'>Regular Sporting Events</h2>
                                                </div>
                                                <div className="accordion-pics-section">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="second-accord-image-section">
                                                                <img src={require('../images/accordion-2-image-1.webp')} alt="" />
                                                                <h2 className='mb-0'>Badminton</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="second-accord-image-section">
                                                                <img src={require('../images/accordion-2-image-2.webp')} alt="" />
                                                                <h2 className='mb-0'>Marathon</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="second-accord-image-section">
                                                                <img src={require('../images/accordion-2-image-3.webp')} alt="" />
                                                                <h2 className='mb-0'>FIFA Tournament</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="second-accord-image-section">
                                                                <img src={require('../images/accordion-2-image-4.webp')} alt="" />
                                                                <h2 className='mb-0'>Cricket</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* temp commnet */}

                                                <div className="accordion-pics-section">
                                                    <div className="adventure-head-sec">
                                                        <h2 className='mb-0'>Fun And Games In The Office</h2>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/fun-game1.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/fun-game2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="hybrid-texter-section">
                                                    {/* <h2 className='mb-0'>Hybrid work to enable work from anywhere</h2>
                                                    <p className='mb-0'>{"<Images to come>"}</p> */}
                                                    <div className="work-hard-section">
                                                        <h2 className='mb-0'>Work Hard And Party Responsibly</h2>
                                                    </div>
                                                </div>
                                                <div className="accord-2-section-3-images">
                                                    <div className="section-3-accord-inner">
                                                        <div className="row">
                                                            {/* <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/accordion-2-group-1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/accordion-2-group-2-1.webp')} alt="" />
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/work-hapr1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/work-hapr2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/work-hapr3.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/work-hapr4.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accord-2-section-3-images">
                                                    <div className="section-3-accord-inner">
                                                        <div className="rainmakers-list-header">
                                                            <h2 className='mb-0'>Rainmakers</h2>
                                                        </div>
                                                        <div className="row">
                                                            {/* <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/rainmaker-1.webp')} alt="" />
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="col-md-6">
                                                                <div className="accord-2-images">
                                                                    <img className='w-100' src={require('../images/rainmaker-2-1.webp')} alt="" />
                                                                </div>
                                                            </div> */}
                                                             <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers1.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers2.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers3.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers4.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers5.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers6.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-pics-section">
                                                        <div className="adventure-head-sec">
                                                            <h2 className='mb-0'>New Office Inauguration </h2>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/new-office1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/new-office2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                            
                                                </div>
                                                <div className="accordion-pics-section">
                                                        <div className="adventure-head-sec">
                                                            <h2 className='mb-0'> A Strong Team On Every Field</h2>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/strong-team1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/strong-team2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                    
                                                        </div>
                                            
                                                </div>
                                                <div className="accordion-pics-section">
                                                    <div className="adventure-head-sec">
                                                        <h2 className='mb-0'>Let's Dance</h2>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance1.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance3.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (3).webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (4).webp')} alt="" />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (1).webp')} alt="" />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                
                                                {/* temp commnet */}
                                                {/* <div className="game-on-section">
                                                    <div className="game-on-header">
                                                        <h2 className='mb-0'>Game on</h2>
                                                    </div>
                                                    <img className='w-100' src={require('../images/gameon.webp')} alt="" />
                                                </div> */}
                                                {/* temp commnet */}
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="bottom-pink-border-div">
                                            
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='bg-properties-class-user accord-itemer-three'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        <div className="accord-head-section">
                                            <h2>#PSTravel</h2>
                                            <p>On-site travel to maximize impact</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="ps-travel-accord-body">
                                            <div className="ps-travel-inner-section">
                                                <div className="ps-travel-para">
                                                    <p className='mb-0'> On-site travel enables us to build deeper connections with clients, 
                                                        collaborate with peers, and understand the  ground situation better,
                                                        eventually allowing us to offer meaningful and impactful advice to our clients
                                                    </p>
                                                </div>
                                                <div className="ps-travel-pics">
                                                    <div className="ps-travel-pics-inner">
                                                        {/* <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-3.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-4.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-4">
                                                                <div className="ps-images">
                                                                    <img src={require('../images/ps-4.webp')} alt="" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottom-ps-days-calc">
                                                    <div className="ps-calc-inner d-flex align-items-center justify-content-between">
                                                        <div className="eleven-days d-flex align-items-center">
                                                            <div className="para-11"><p className='mb-0 text-white'>11</p></div>
                                                            <div className="citis-texter"><p className='mb-0 text-white'>Cities <br /> Visited</p></div>
                                                        </div>
                                                        <div className="anime-spanner-top">
                                                            <span className='vertical-spanner'></span>
                                                        </div>
                                                        <div className="eleven-days d-flex align-items-center">
                                                            <div className="para-11"><p className='mb-0 text-white'>655</p></div>
                                                            <div className="citis-texter"><p className='mb-0 text-white'>Travel <br /> Days</p></div>
                                                        </div>
                                                        <div className="anime-spanner-top">
                                                            <span className='vertical-spanner'></span>
                                                        </div>
                                                        <div className="eleven-days d-flex align-items-center">
                                                            <div className="para-11"><p className='mb-0 text-white'>15</p></div>
                                                            <div className="citis-texter"><p className='mb-0 text-white'>Days of <br /> Average Trip</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-pink-border-div">
                                        
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" className='bg-properties-class-user accord-itemer-four'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        <div className="accord-head-section">
                                            <h2>#PSPrayas</h2>
                                            <p>Doing our bit for the Society</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="ps-travel-accord-body">
                                            <div className="ps-travel-inner-section">
                                                <div className="ps-travel-para">
                                                    <p className='mb-0'> PS Prayas is the internal committee of PS, established to oversee, guide, and drive the company's corporate social responsibility (CSR) initiatives. Our mission is to be a catalyst in children's lives by harnessing our collective power to create a lasting impact. We believe that every child, regardless of their background, deserves the tools and support necessary to dream big and achieve even bigger.
                                                    </p>
                                                </div>
                                                <div className="cata-lists-images">
                                                    <div className="row">
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas1} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas2} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas3} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas4} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas5} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas6} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas7} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas8} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas9} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas10} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas11} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas12} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas13} alt="" /></div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-pink-border-div">
                                        
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                             
                                <Accordion.Item eventKey="4" className='bg-properties-class-user accord-itemer-five'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        <div className="accord-head-section">
                                            <h2>#Learning@PS</h2>
                                            <p>Always working towards achieving excellence</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                            <div className="ps-travel-accord-body">
                                                <div className="ps-travel-inner-section">
                                                    <div className="ps-travel-para">
                                                        <p className='mb-0'> We understand the importance of peer 
                                                            learning and have multiple forums to ensure knowledge 
                                                            sharing within the company
                                                        </p>
                                                    </div>

                                                    <div className="cata-lists-images">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="master-class-headers">
                                                                    <h2 className='mb-0 text-white'>Learning Cafe</h2>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe01.webp')} alt="" /></div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe02.webp')} alt="" /></div>
                                                                    </div>
                                                                    {/* <div className="col-md-4">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe3.webp')} alt="" /></div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="master-class-headers mt-4">
                                                                    <h2 className='mb-0 text-white'>Masterclass</h2>
                                                                </div>
                                                                <div className="row">
                                                                    {/* <div className="col-md-4">
                                                                         <div className="catalysts-images-section"><img src={require('../images/learning-2.webp')} alt="" /></div>
                                                                    </div> */}
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/masterclass1.webp')} alt="" /></div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/masterclass2.webp')} alt="" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bottom-pink-border-div">
                                            
                                            </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5" className='bg-properties-class-user accord-itemer-six'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="border-divver">

                                        </div>
                                        <div className="accord-head-section">
                                            <h2>#PSDiaries</h2>
                                            <p>A journey worth sharing</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="ps-travel-accord-body">
                                            <div className="ps-travel-inner-section">
                                                <div className="ps-dairy-rows-section admins-sec">
                                                    {/* <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="about-sec-image">
                                                                <img src={require('../images/Archit-Ganeriwal.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="about-admin-para-sec">
                                                                <p className='mb-0 text-white'>“PS has enabled a tectonic shift in my mindset. There is a popular saying in the company, “Consulting is a way of life”, which succinctly defines my experience and the profound impact PS has had on my personality. It is essentially a cohort which facilitates fluid knowledge transfer to allow for collective development. Philosophical disagreements are encouraged. The team is expected to think for themselves rather than execute aimlessly. We expect our team members to be thought leaders rather than execution monkeys”</p>
                                                                <div className="bottom-details-sec">
                                                                    <p className='mb-0 text-white'><span className='text-white'>Archit Generiwal, </span>Consultant</p>
                                                                    <p className='mb-0 text-white small-text-bottom'>Working since April'22</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row middle-row-section-admin">
                                                        <div className="col-md-4">
                                                            <div className="about-sec-image">
                                                                <img src={require('../images/dairie-2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="about-admin-para-sec">
                                                                <p className='mb-0 text-white'>
                                                                    “PS provided me the perfect platform to launch my career as a management consultant. 
                                                                    Joining through the ranks of BA and being able to reach continuous milestones aligning with the 
                                                                    growth trajectory of PS gives me immense pride & pleasure. The mantra of putting the team ahead
                                                                    and serving the clients to the best of our abilities is what make PS vibrant, filled with colors 
                                                                    of sweat, success, failures, but above all, happiness and job satisfaction. On work front, being a 
                                                                    front-end management consultant is something a lot of us dream. I'm glad I get to be one each day, 
                                                                    diligently helping clients serve their corporate roles and responsibilities. The fact that we get to 
                                                                    interact with CXO suite of top firms across sectors focused across KSA, EU, etc. has significantly 
                                                                    impacted my overall growth and professional experience. The real-time experience of providing onsite 
                                                                    consulting/advisory services has further enrichen my journey”
                                                                </p>
                                                                <div className="bottom-details-sec">
                                                                    <p className='mb-0 text-white'><span className='text-white'>Vatsal Bass, </span>Consultant</p>
                                                                    <p className='mb-0 text-white small-text-bottom'>Working since Dec'20</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="about-sec-image">
                                                                <img src={require('../images/dairie-3.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="about-admin-para-sec">
                                                                <p className='mb-0 text-white'>
                                                                    “My overall experience with the PS over the last 3 years has been fantastic. 
                                                                    I have had the opportunity to learn and grow both personally and professionally, 
                                                                    there is always something challenging happening and every day is filled with new 
                                                                    learning opportunities. The exceptional knowledge transfer across various projects, 
                                                                    along with the support of an excellent leadership team, has been benefited me a lot.
                                                                    I am truly grateful to work in an environment where the culture feels like a family 
                                                                    atmosphere, and where teamwork is given a strong emphasis”
                                                                </p>
                                                                <div className="bottom-details-sec">
                                                                    <p className='mb-0 text-white'><span className='text-white'>Syed Yusuf Suhai, </span>Senior Data Analyst</p>
                                                                    <p className='mb-0 text-white small-text-bottom'>Working since Jan'21</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Aryan Tuteja.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>Joining Preferred Square has been a journey of growth in a vibrant, collaborative culture. 
                                                                        The firm's commitment to client-focused solutions has helped me gain invaluable business insights, significantly deepening my knowledge and broadening my horizons. 
                                                                        The on-site travel opportunities have enriched my experience, providing the chance to work closely with the CXO layer. As the saying goes,
                                                                        "Consulting is about shaping solutions, but it's also about shaping yourself"—Preferred Square exemplifies this every day.
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Aryan Tuteja
                                                                         </span> Senior Business Analyst</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since April'22</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row middle-row-section-admin">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Arpit Katyal.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                    PS has been pivotal in both my professional & personal growth. The Company has nurtured 
                                                                    my curiosity into fostering a solution-oriented mindset and appreciation for diverse perspectives.
                                                                     In the 2+ years I've been associated with PS, I've learned the importance prioritizing our clients 
                                                                     and the team over individual growth. I can proudly say that I found a family at PS, where we strive 
                                                                     for excellence and aim to be leaders of our generation.

                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Arpit Katyal </span>Consultant</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since Dec'20</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Ritika Kapoor.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                    PS has been instrumental in launching my career, offering a platform that fosters a steep learning curve and rapid development. 
                                                                    Starting as a Business Analyst and advancing to a Senior Business Analyst, I have refined my technical and soft skills through front-end 
                                                                    consulting and direct interactions with CXO-level clients. These experiences have been pivotal in shaping my professional journey. Additionally, 
                                                                    participating in various events has enriched my skills and contributed to a balanced and dynamic work environment.
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Ritika Kapoor </span>Senior Business Analyst</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since Jan'21</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-pink-border-div">
                                        
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="after-992-life-at-ps-container">
            <div className='row life-at-preffer-row-section'>
                <div className="col-md-5 px-0">
                   <div className="container">
                        <div className="after-992-ethos-header-content">
                            <div className="life-left-side-openings-header">
                                <div className="life-left-job-open-column-inner position-relative">
                                    <div className="life-job-absolute-text">
                                        <p>Ethos</p>
                                    </div>
                                    <div className="life-latest-p-section">
                                        <p className='text-white mb-0'>Ethos of</p>
                                    </div>
                                    <div className="life-bottom-jobber-section">
                                        <h2 className='text-white mb-0'><span>Life </span>@PS</h2>
                                    </div>
                                    <div className="life-bottom-jobber-section-mobile-viewer">
                                        <h2 className='text-white  mb-0'>Life @PS</h2>
                                        <div className="life-animator-we-bottom-767-after">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <div className="col-md-7">                    
                    <div className="right-side-accordion-content after-992-content-accordion-main">
                        <div className="right-side-accordion-inner">
                            <Accordion >  
                                <Accordion.Item eventKey="0" className='bg-properties-class-user accord-itemer-one'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#About Us</h2>
                                                <p>Learn about principles guiding Preferred Square</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="about-us-accord-body">
                                                <div className="about-us-accord-button text-center">
                                                <NavLink to="/about" onClick={scrollToTop} ><button className="text-center">Learn more about us</button></NavLink>
                                                </div>
                                            </div> 
                                        </div>                                  
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='bg-properties-class-user accord-itemer-two'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#Preferred4Life</h2>
                                                <p>Life at Preferred Square is more than just work</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="accordion-inner-body-section">
                                                <div className="accordion-preffered-list">
                                                    {/* temp comment */}
                                                    {/* <div className="preffered-list-header">
                                                        <h2 className='mb-0'>Regular Sporting Events</h2>
                                                    </div>
                                                    <div className="accordion-pics-section">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="second-accord-image-section">
                                                                    <img src={require('../images/accordion-2-image-1.webp')} alt="" />
                                                                    <h2 className='mb-0'>Badminton</h2>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="second-accord-image-section">
                                                                    <img src={require('../images/accordion-2-image-2.webp')} alt="" />
                                                                    <h2 className='mb-0'>Marathon</h2>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="second-accord-image-section">
                                                                    <img src={require('../images/accordion-2-image-3.webp')} alt="" />
                                                                    <h2 className='mb-0'>FIFA Tournament</h2>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="second-accord-image-section">
                                                                    <img src={require('../images/accordion-2-image-4.webp')} alt="" />
                                                                    <h2 className='mb-0'>Cricket</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* temp comment */}
                                                    
                                                    <div className="accordion-pics-section">
                                                        <div className="adventure-head-sec">
                                                            <h2 className='mb-0'>Fun and games in the office</h2>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/fun-game1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/fun-game2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="hybrid-texter-section">
                                                        {/* <h2 className='mb-0'>Hybrid work to enable work from anywhere</h2>
                                                        <p className='mb-0'>{"<Images to come>"}</p> */}
                                                        <div className="work-hard-section">
                                                            <h2 className='mb-0'>Work hard and party responsibly</h2>
                                                        </div>
                                                    </div>
                                                    <div className="accord-2-section-3-images">
                                                        <div className="section-3-accord-inner">
                                                            <div className="row">
                                                                {/* <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/accordion-2-group-1.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/accordion-2-group-2-1.webp')} alt="" />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/work-hapr1.webp')} alt="" />
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="accord-2-images">
                                                                            <img className='w-100' src={require('../images/work-hapr2.webp')} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mt-2">
                                                                        <div className="accord-2-images">
                                                                            <img className='w-100' src={require('../images/work-hapr3.webp')} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mt-2">
                                                                        <div className="accord-2-images">
                                                                            <img className='w-100' src={require('../images/work-hapr4.webp')} alt="" />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accord-2-section-3-images">
                                                        <div className="section-3-accord-inner">
                                                            <div className="rainmakers-list-header">
                                                                <h2 className='mb-0'>Rainmakers</h2>
                                                            </div>
                                                            <div className="row">
                                                                {/* <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/rainmaker-1.webp')} alt="" />
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/rainmaker-2-1.webp')} alt="" />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers1.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers2.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers3.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers4.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers5.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="accord-2-images">
                                                                        <img className='w-100' src={require('../images/Rainmakers6.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-pics-section">
                                                        <div className="adventure-head-sec">
                                                            <h2 className='mb-0'>New Office Inauguration </h2>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/new-office1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/new-office2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-pics-section">
                                                    <div className="adventure-head-sec">
                                                        <h2 className='mb-0'>Let's Dance</h2>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance1.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/lets-dance3.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (3).webp')} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (4).webp')} alt="" />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-4">
                                                            <div className="adventure-image-sec">
                                                                <img className='w-100' src={require('../images/accordion-adventure-section-image (1).webp')} alt="" />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="accordion-pics-section">
                                                        <div className="adventure-head-sec">
                                                            <h2 className='mb-0'> A strong team on every field                                                            </h2>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/strong-team1.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="adventure-image-sec">
                                                                    <img className='w-100' src={require('../images/strong-team2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                    
                                                        </div>
                                            
                                                </div>

                                                    {/* temp commnet */}
                                                    {/* <div className="game-on-section">
                                                        <div className="game-on-header">
                                                            <h2 className='mb-0'>Game on</h2>
                                                        </div>
                                                        <img className='w-100' src={require('../images/gameon.webp')} alt="" />
                                                    </div>
                                                    <div className="game-on-section-2section">
                                                        <div className="game-on-header">
                                                            <h2 className='mb-0'>Game on</h2>
                                                        </div>
                                                        <img className='w-100' src={require('../images/gameno2.webp')} alt="" />
                                                    </div> */}
                                                    {/* temp commnet */}
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>                                    
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='bg-properties-class-user accord-itemer-three'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#PSTravel</h2>
                                                <p>On-site travel to maximize impact</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="ps-travel-accord-body">
                                                <div className="ps-travel-inner-section">
                                                    <div className="ps-travel-para">
                                                        <p className='mb-0'> On-site travel enables us to build deeper connections with clients, 
                                                            collaborate with peers, and understand the  ground situation better,
                                                            eventually allowing us to offer meaningful and impactful advice to our clients
                                                        </p>
                                                    </div>
                                                    <div className="ps-travel-pics">
                                                        <div className="ps-travel-pics-inner">
                                                            {/* <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-1.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-2.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-3.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-4.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div> */}
<div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-1.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-2.webp')} alt="" />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-3">
                                                                    <div className="ps-images">
                                                                        <img src={require('../images/ps-4.webp')} alt="" />
                                                                    </div>
                                                                </div> */}
                                                            </div>                                                        </div>
                                                    </div>
                                                    <div className="bottom-ps-days-calc">
                                                        <div className="ps-calc-inner d-flex align-items-center justify-content-between">
                                                            <div className="eleven-days d-flex align-items-center">
                                                                <div className="para-11"><p className='mb-0 text-white'>11</p></div>
                                                                <div className="citis-texter"><p className='mb-0 text-white'>Cities <br /> Visited</p></div>
                                                            </div>
                                                            <div className="anime-spanner-top">
                                                                <span className='vertical-spanner'></span>
                                                            </div>
                                                            <div className="eleven-days d-flex align-items-center">
                                                                <div className="para-11"><p className='mb-0 text-white'>655</p></div>
                                                                <div className="citis-texter"><p className='mb-0 text-white'>Travel <br /> Days</p></div>
                                                            </div>
                                                            <div className="anime-spanner-top">
                                                                <span className='vertical-spanner'></span>
                                                            </div>
                                                            <div className="eleven-days d-flex align-items-center">
                                                                <div className="para-11"><p className='mb-0 text-white'>15</p></div>
                                                                <div className="citis-texter"><p className='mb-0 text-white'>Days of <br /> Average Trip</p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>                                  
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3" className='bg-properties-class-user accord-itemer-four'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#PSPrayas</h2>
                                                <p>Doing our bit for the Society</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="ps-travel-accord-body">
                                                <div className="ps-travel-inner-section">
                                                    <div className="ps-travel-para">
                                                    <p className='mb-0'> PS Prayas is the internal committee of PS, established to oversee, guide, and drive the company's corporate social responsibility (CSR) initiatives. Our mission is to be a catalyst in children's lives by harnessing our collective power to create a lasting impact. We believe that every child, regardless of their background, deserves the tools and support necessary to dream big and achieve even bigger.
                                                    </p>
 
                                                    </div>
                                                    <div className="cata-lists-images">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                 <div className="catalysts-images-section"><img src={parayas1} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="catalysts-images-section"><img src={parayas2} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas3} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas4} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas5} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas6} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas7} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas8} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas9} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas10} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas11} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas12} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <div className="catalysts-images-section"><img src={parayas13} alt="" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                <Accordion.Item eventKey="4" className='bg-properties-class-user accord-itemer-five'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#Learning@PS</h2>
                                                <p>Always working towards achieving excellence</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="ps-travel-accord-body">
                                                <div className="ps-travel-inner-section">
                                                    <div className="ps-travel-para">
                                                        <p className='mb-0'> We understand the importance of peer 
                                                            learning and have multiple forums to ensure knowledge 
                                                            sharing within the company
                                                        </p>
                                                    </div>

                                                    {/* <div className="cata-lists-images">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="master-class-headers">
                                                                    <h2 className='mb-0 text-white'>Learning Cafe</h2>
                                                                </div>
                                                                <div className="catalysts-images-section"><img src={require('../images/learning-cafe1.webp')} alt="" /></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="master-class-headers">
                                                                    <h2 className='mb-0 text-white'>Masterclass</h2>
                                                                </div>
                                                                <div className="catalysts-images-section"><img src={require('../images/learning-2.webp')} alt="" /></div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                     <div className="cata-lists-images">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="master-class-headers">
                                                                    <h2 className='mb-0 text-white'>Learning Cafe</h2>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe01.webp')} alt="" /></div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe02.webp')} alt="" /></div>
                                                                    </div>
                                                                    {/* <div className="col-md-4">
                                                                        <div className="catalysts-images-section"><img src={require('../images/learning-cafe3.webp')} alt="" /></div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="master-class-headers mt-2">
                                                                    <h2 className='mb-0 text-white'>Masterclass</h2>
                                                                </div>
                                                                <div className="row">
                                                                    {/* <div className="col-md-4">
                                                                         <div className="catalysts-images-section"><img src={require('../images/learning-2.webp')} alt="" /></div>
                                                                    </div> */}
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/masterclass1.webp')} alt="" /></div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="catalysts-images-section"><img src={require('../images/masterclass2.webp')} alt="" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5" className='bg-properties-class-user accord-itemer-six'>
                                    <div className="green-border-diver">

                                    </div>
                                    <Accordion.Header>
                                        <div className="container">
                                            <div className="border-divver">

                                            </div>
                                            <div className="accord-head-section">
                                                <h2>#PSDiaries</h2>
                                                <p>A journey worth sharing</p>
                                                <div className="after-arrow-image-section">
                                                    <div className="inner-arrow-image-section">
                                                        
                                                    </div>
                                                </div>
                                                <div className="after-992-green-topper">
                                                    <div className="inner-after-992-greener">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="container">
                                            <div className="ps-travel-accord-body">
                                                <div className="ps-travel-inner-section">
                                                    <div className="ps-dairy-rows-section admins-sec">
                                                        {/* <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Archit-Ganeriwal.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>“PS has enabled a tectonic shift in my mindset. There is a popular saying in the company, “Consulting is a way of life”, which succinctly defines my experience and the profound impact PS has had on my personality. It is essentially a cohort which facilitates fluid knowledge transfer to allow for collective development. Philosophical disagreements are encouraged. The team is expected to think for themselves rather than execute aimlessly. We expect our team members to be thought leaders rather than execution monkeys”</p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Archit Generiwal, </span>Consultant</p>
                                                                        <p className='mb-0 text-white small-text-bottom'>Working since April'22</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row middle-row-section-admin">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/dairie-2.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                        “PS provided me the perfect platform to launch my career as a management consultant. 
                                                                        Joining through the ranks of BA and being able to reach continuous milestones aligning with the 
                                                                        growth trajectory of PS gives me immense pride & pleasure. The mantra of putting the team ahead
                                                                        and serving the clients to the best of our abilities is what make PS vibrant, filled with colors 
                                                                        of sweat, success, failures, but above all, happiness and job satisfaction. On work front, being a 
                                                                        front-end management consultant is something a lot of us dream. I'm glad I get to be one each day, 
                                                                        diligently helping clients serve their corporate roles and responsibilities. The fact that we get to 
                                                                        interact with CXO suite of top firms across sectors focused across KSA, EU, etc. has significantly 
                                                                        impacted my overall growth and professional experience. The real-time experience of providing onsite 
                                                                        consulting/advisory services has further enrichen my journey”
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Vatsal Bass, </span>Consultant</p>
                                                                        <p className='mb-0 text-white small-text-bottom'>Working since Dec'20</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/dairie-3.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                        “My overall experience with the PS over the last 3 years has been fantastic. 
                                                                        I have had the opportunity to learn and grow both personally and professionally, 
                                                                        there is always something challenging happening and every day is filled with new 
                                                                        learning opportunities. The exceptional knowledge transfer across various projects, 
                                                                        along with the support of an excellent leadership team, has been benefited me a lot.
                                                                        I am truly grateful to work in an environment where the culture feels like a family 
                                                                        atmosphere, and where teamwork is given a strong emphasis”
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Syed Yusuf Suhai, </span>Senior Data Analyst</p>
                                                                        <p className='mb-0 text-white small-text-bottom'>Working since Jan'21</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Aryan Tuteja.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>Joining Preferred Square has been a journey of growth in a vibrant, collaborative culture. 
                                                                        The firm's commitment to client-focused solutions has helped me gain invaluable business insights, significantly deepening my knowledge and broadening my horizons. 
                                                                        The on-site travel opportunities have enriched my experience, providing the chance to work closely with the CXO layer. As the saying goes,
                                                                        "Consulting is about shaping solutions, but it's also about shaping yourself"—Preferred Square exemplifies this every day.
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Aryan Tuteja
                                                                         </span></p>
                                                                         <p className='text-white'>Senior Business Analyst</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since April'22</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row middle-row-section-admin">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Arpit Katyal.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                    PS has been pivotal in both my professional & personal growth. The Company has nurtured 
                                                                    my curiosity into fostering a solution-oriented mindset and appreciation for diverse perspectives.
                                                                     In the 2+ years I've been associated with PS, I've learned the importance prioritizing our clients 
                                                                     and the team over individual growth. I can proudly say that I found a family at PS, where we strive 
                                                                     for excellence and aim to be leaders of our generation.

                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Arpit Katyal </span></p>
                                                                        <p className='text-white'>Consultant</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since Dec'20</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="about-sec-image">
                                                                    <img src={require('../images/Ritika Kapoor.webp')} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="about-admin-para-sec">
                                                                    <p className='mb-0 text-white'>
                                                                    PS has been instrumental in launching my career, offering a platform that fosters a steep learning curve and rapid development. 
                                                                    Starting as a Business Analyst and advancing to a Senior Business Analyst, I have refined my technical and soft skills through front-end 
                                                                    consulting and direct interactions with CXO-level clients. These experiences have been pivotal in shaping my professional journey. Additionally, 
                                                                    participating in various events has enriched my skills and contributed to a balanced and dynamic work environment.
                                                                    </p>
                                                                    <div className="bottom-details-sec">
                                                                        <p className='mb-0 text-white'><span className='text-white'>Ritika Kapoor </span></p>
                                                                        <p className='text-white'>Senior Business Analyst</p>
                                                                        {/* <p className='mb-0 text-white small-text-bottom'>Working since Jan'21</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </section>
    </>
  )
}