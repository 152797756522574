import  {useState} from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import HeaderSolution from './component/HeaderSolution';
import { Routes, Route, NavLink } from "react-router-dom";
// import { createBrowserHistory } from 'history';
//import Menu from './component/Menu';
import Home from './pages/Home';
import Solution from './pages/Solution';
import Knowledge from './pages/Knowledge';
import Service from './pages/Service';
import Contact from './pages/Contact';
import About from './pages/About';
import Managementconsulting from './pages/Managementconsulting';
import Financialadvisory from './pages/Financialadvisory';
import Dataanalyst from './pages/Dataanalyst';
import Career from './pages/Career';
import { Helmet } from 'react-helmet';
//import JobOpening from './component/Jobopeningheader';
import Jobopening from './pages/Jobopening';
import Job2 from './pages/Job2';
// import Insight from './component/Insight';
import Single from './pages/Single';
import Terms from './pages/Terms';
import Privacypolicy from './pages/Privacypolicy';
import CaseStudy1 from './pages/CaseStudy1';
import CaseStudy2 from './pages/CaseStudy2';
import CaseStudy3 from './pages/CaseStudy3';
import CaseStudy4 from './pages/CaseStudy4';
import CaseStudy5 from './pages/CaseStudy5';
import CaseStudy6 from './pages/CaseStudy6';
import CaseStudy7 from './pages/CaseStudy7';
import CaseStudy8 from './pages/CaseStudy8';
import CaseStudy9 from './pages/CaseStudy9';
import CaseStudy10 from './pages/CaseStudy10';
import CaseStudy11 from './pages/CaseStudy11';
import CaseStudy12 from './pages/CaseStudy12';

import Page404 from './pages/Page404';

import CookieConsent from "react-cookie-consent";
import { Container } from 'react-bootstrap';
import CaseStudy13 from './pages/CaseStudy13';
import CaseStudy14 from './pages/CaseStudy14';
import CaseStudy15 from './pages/CaseStudy15';
import CaseStudy16 from './pages/CaseStudy16';
import CaseStudy17 from './pages/CaseStudy17';
import CaseStudy18 from './pages/CaseStudy18';
import CaseStudy19 from './pages/CaseStudy19';
import CaseStudy20 from './pages/CaseStudy20';
import CaseStudy21 from './pages/CaseStudy21';
import CaseStudy22 from './pages/CaseStudy22';

// import Insight from './component/Insight';

// import Header from './component/Header';
// import Footer from "./component/Footer";
// import { Getintouch } from './component/Getintouch';
// import { Whyus } from './component/Whyus';
// import { Weserve } from './component/Weserve';
// import { ArrowSection } from './component/ArrowSection';

function App() {

  // const history = createBrowserHistory({
  //   //basename: "/staging/preferred-square"
  //   basename: "/"
  // });

 const [blank, setBlank] = useState(false);
  let creat_url = (str) => {
  str = str.split(/\s+/).join("-");
  str = str.toLowerCase();
  str = "/knowledge/" + str;
  return str;
 }

 const CaseStudyurl1 = creat_url('Expediting the Investment Process Through a Structured Roadmap');
 const CaseStudyurl2 = creat_url('Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy');
 const CaseStudyurl3 = creat_url('Streamlining Business And Facilitating The Implementation Of An ERP System');
 const CaseStudyurl4 = creat_url('Enabling A Real Estate Conglomerate To Secure US 40 Mn Funding to Develop A Logistics Park');
 const CaseStudyurl5 = creat_url('Strategic Investment Blueprint For A Global Pharma Player');
 const CaseStudyurl6 = creat_url('Cultivating Employee Engagement Insights & Interventions for a Leading Conglomerate');
 const CaseStudyurl7 = creat_url('Corporate Strategy For a Real Estate Development Company');
 const CaseStudyurl8 = creat_url('Strategic Consulting for a PropTech Real Estate Equity Crowdfunding Platform in KSA');
 const CaseStudyurl9 = creat_url('Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S');
 const CaseStudyurl10 = creat_url('Vendor & Counter Party Due Diligence For A Government-Backed KSA Entity');
 const CaseStudyurl11 = creat_url('Private Equity Valuation Model for an Investment Firm');
 const CaseStudyurl12 = creat_url('Investment Policy Statement for an Endowment Fund');
 const CaseStudyurl13 = creat_url('Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund');
 const CaseStudyurl14 = creat_url('Human Resource Reporting Dashboard For A Company In The Hospitality Industry');
 const CaseStudyurl15 = creat_url('Funding Strategy For A Real Estate Development Subsidiary');
 const CaseStudyurl16 = creat_url('Enterprise Project Management Office For A Real Estate Development Company');
 const CaseStudyurl17 = creat_url('Consolidated Financial Model  for a KSA-Based Real Estate Development Project');
 const CaseStudyurl18 = creat_url('Investment Memorandum for an Investment Firm');
 const CaseStudyurl19 = creat_url('Optimizing Business Processes For A Real Estate Developer');
 const CaseStudyurl20 = creat_url('Financial Due Diligence For A U.S.-Based Private Equity Firm');
 const CaseStudyurl21 = creat_url('Corporate Strategy For A Single-Family Office');
 const CaseStudyurl22 = creat_url('Customized BI Dashboards for an Investment Corporate');
  

 return (
    <>
    <Helmet>
      <title>Preferred Square</title>
      <meta name='description' content=''/>
      <meta name='keywords' content=''/>
      <script type="application/ld+json">
      {`{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "Preferred Square | Every Decision Better Informed",
            "item": "https://preferred-square.com/"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "About",
            "item": "https://preferred-square.com/about"  
          },{
            "@type": "ListItem", 
            "position": 3, 
            "name": "Careers",
            "item": "https://preferred-square.com/careers"  
          },{
            "@type": "ListItem", 
            "position": 4, 
            "name": "Knowledge",
            "item": "https://preferred-square.com/knowledge"  
          },{
            "@type": "ListItem", 
            "position": 5, 
            "name": "Services",
            "item": "https://preferred-square.com/service"  
          },{
            "@type": "ListItem", 
            "position": 6, 
            "name": "Contact Us",
            "item": "https://preferred-square.com/contact"  
          }]
        }`}
        </script>
    </Helmet>
    <div className="App">
{/* <Menu /> */}
{/* <BrowserRouter basename='/' history={history}> */}

    {(blank) ? <div className='blank'> </div> : ""}
      <Routes>
        <Route exact path="/" element={<Home setBlank={setBlank} />} >
          </Route>
          <Route exact path="about" element={<About setBlank={setBlank} />} />
          <Route exact path="solution" element={<Solution setBlank={setBlank} />} />
          <Route exact path="service" element={<Service setBlank={setBlank} />} />
          <Route exact path="knowledge" element={<Knowledge setBlank={setBlank} />} />
          <Route exact path="careers" element={<Career setBlank={setBlank} />} />
          <Route exact path="contact" element={<Contact setBlank={setBlank} />} />
          <Route exact path="managementconsulting" element={<Managementconsulting setBlank={setBlank} />} />
          <Route exact path="investmentadvisory" element={<Financialadvisory setBlank={setBlank} />} />
          <Route exact path="dataanalytics" element={<Dataanalyst setBlank={setBlank} />} />        
          <Route exact path="jobopening" element={<Jobopening setBlank={setBlank} />} />    
          <Route exact path="termsandconditions" element={<Terms setBlank={setBlank} />} />    
          <Route exact path="privacypolicy" element={<Privacypolicy setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl1} element={<CaseStudy1 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl2} element={<CaseStudy2 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl3} element={<CaseStudy3 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl4} element={<CaseStudy4 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl5} element={<CaseStudy5 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl6} element={<CaseStudy6 setBlank={setBlank} />} />    
          <Route exact path={CaseStudyurl7} element={<CaseStudy7 setBlank={setBlank} />} /> 
          <Route exact path={CaseStudyurl8} element={<CaseStudy8 setBlank={setBlank} />} />       
          <Route exact path={CaseStudyurl9} element={<CaseStudy9 setBlank={setBlank} />} />       
          <Route exact path={CaseStudyurl10} element={<CaseStudy10 setBlank={setBlank} />} />      
          <Route exact path={CaseStudyurl11} element={<CaseStudy11 setBlank={setBlank} />} />      
          <Route exact path={CaseStudyurl12} element={<CaseStudy12 setBlank={setBlank} />} />      
          <Route exact path={CaseStudyurl13} element={<CaseStudy13 setBlank={setBlank} />} />  
          <Route exact path={CaseStudyurl14} element={<CaseStudy14 setBlank={setBlank} />} />                
          <Route exact path={CaseStudyurl15} element={<CaseStudy15 setBlank={setBlank} />} />   
          <Route exact path={CaseStudyurl16} element={<CaseStudy16 setBlank={setBlank} />} />   
          <Route exact path={CaseStudyurl17} element={<CaseStudy17 setBlank={setBlank} />} />   
          <Route exact path={CaseStudyurl18} element={<CaseStudy18 setBlank={setBlank} />} />   
          <Route exact path={CaseStudyurl19} element={<CaseStudy19 setBlank={setBlank} />} />  
          <Route exact path={CaseStudyurl20} element={<CaseStudy20 setBlank={setBlank} />} />  
          <Route exact path={CaseStudyurl21} element={<CaseStudy21 setBlank={setBlank} />} />  
          <Route exact path={CaseStudyurl22} element={<CaseStudy22 setBlank={setBlank} />} />  
                       
          <Route exact path="casestudy1" element={<CaseStudy1 setBlank={setBlank} />} />    
          <Route exact path="casestudy2" element={<CaseStudy2 setBlank={setBlank} />} />    
          <Route exact path="casestudy3" element={<CaseStudy3 setBlank={setBlank} />} />    
          <Route exact path="casestudy4" element={<CaseStudy4 setBlank={setBlank} />} />    
          <Route exact path="casestudy5" element={<CaseStudy5 setBlank={setBlank} />} />    
          <Route exact path="casestudy6" element={<CaseStudy6 setBlank={setBlank} />} />    
          <Route exact path="casestudy7" element={<CaseStudy7 setBlank={setBlank} />} />    
          <Route exact path="casestudy8" element={<CaseStudy8 setBlank={setBlank} />} />    
          <Route exact path="casestudy9" element={<CaseStudy9 setBlank={setBlank} />} /> 
          <Route exact path="single/:id/:title" element={ <Single setBlank={setBlank} />}  /> 
          <Route exact path="businessanalyst" element={<Jobopening setBlank={setBlank} />} />    
          <Route exact path="dataanalyst" element={<Job2 setBlank={setBlank} />} />    
          
          
          <Route exact path="*" element={ <Page404 setBlank={setBlank} />}  /> 
        
            
      </Routes>
    {/* </BrowserRouter> */}

      {/* <HeaderSolution /> */}
      {/* <Header />
      <ArrowSection />
      <Weserve />
      <Whyus />
      <Getintouch />
      <Footer /> */}
    
       <CookieConsent
          debug={false}
          location="bottom"
          flipButtons
          rcc-confirm-button="cookie-btn-wrapper"
          // buttonClasses="cookie-btn-wrapper"
          buttonText="Accept All Cookies"
          enableDeclineButton={true}
          buttonClasses="my-button-class"
          declineButtonText="Decline cookies"
          customButtonWrapperAttributes='<div className"cookie-btn-wrapper"></div>'
          cookieName="CookieConsent"
          declineButtonStyle={{
            color: "#D8D6F6",
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Helvetica",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
            background:"none",
            textDecorationLine: "underline"
          }}
          style={{ background: "#0A142F",padding:"25px",fontSize:"20px", }}
          buttonStyle={{ color: "#FFFFFF",
            fontSize: "18px",
            fontFamily: "Helvetica",
            border: "1px solid #FFF",
            padding:"15px 20px",
            background: "linear-gradient(180deg, #01C6B2 0%, #048D7F 98.44%)",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)" 
          }}
          expires={1000}
        >
         <Container>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site
         navigation, analysis of site usage, and assist in our marketing efforts. Go to our <NavLink style={{color:"#03AD9C"}} to="/privacypolicy">privacy policy</NavLink> to know more
         about them 
         </Container>        
    </CookieConsent>
    </div>
    </>
  );
}

export default App;
